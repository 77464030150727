import {
  useRouterContext,
} from "@pankod/refine-core";
import {
    createStyles,
    Image,
    Container,
    Title,
    Button,
    Group,
    Text,
    List,
    ThemeIcon,
  } from '@mantine/core';
import { IconCheck } from '@tabler/icons';
import { useGetIdentity } from "@pankod/refine-core";

import {
  NavLink
} from "@pankod/refine-mantine";
import {
  IconUser
} from "@tabler/icons";

  
  const useStyles = createStyles((theme) => ({
    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: theme.spacing.xl * 4,
      paddingBottom: theme.spacing.xl * 4,
    },
  
    content: {
      maxWidth: 800,
      marginRight: theme.spacing.xl * 3,
  
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        marginRight: 0,
      },
    },
  
    title: {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: 44,
      lineHeight: 1.2,
      fontWeight: 900,
  
      [theme.fn.smallerThan('xs')]: {
        fontSize: 28,
      },
    },
  
    control: {
      [theme.fn.smallerThan('xs')]: {
        flex: 1,
      },
    },
  
    image: {
      flex: 1,
  
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },
  
    highlight: {
      position: 'relative',
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      borderRadius: theme.radius.sm,
      padding: '4px 12px',
    },
  }));
  
  export function Dashboard() {
    const { Link } = useRouterContext();
    const { data: user } = useGetIdentity();
    const profil_url = "/praticiens/edit/" + user?.data.id_praticien

    const { classes } = useStyles();
    return (
      <div>
        <Container>
          <div className={classes.inner}>
            <div className={classes.content}>
              <Title className={classes.title}>
              Votre profil membre
              </Title>
              <Text color="dimmed" mt="md">
              Nous vous invitons à compléter votre fiche membre Spring Medicare.
              Seuls les profils suffisamment renseignés sont publiés sur notre site. 
              Ces informations sont nécessaires afin d'informer vos patients et améliorer votre visibilité sur internet. 
              </Text>
  
              <List
                mt={30}
                spacing="sm"
                size="sm"
                icon={
                  <ThemeIcon size={20} radius="xl">
                    <IconCheck size={12} stroke={1.5} />
                  </ThemeIcon>
                }
              >
                <List.Item>
                  <b>Description de votre activité</b> : présentation, discipline, séance, formation, etc.
                </List.Item>
                <List.Item>
                  <b>Photos</b> : praticiens, lieu d'exercice, etc.
                </List.Item>
                <List.Item>
                  <b>Liens</b>: site internet, réseaux sociaux, etc.
                </List.Item>
              </List>
  
              <Group mt={30}>
                <Button radius="xl" size="md" className={classes.control}>
                  <NavLink
                    key="user"
                    label="Personnaliser ma fiche"
                    icon={<IconUser />}
                    component={Link}
                    to= {profil_url}
                  />
                </Button>
                <Button variant="default" radius="xl" size="md" className={classes.control}>
                  <Text component="a" fz="sm" target="_blank" rel="noreferrer" href="https://www.spring-medicare.fr/solution/membre/premium/">
                  Passez à l’offre Koudepouce Premium
                  </Text>
                </Button>
              </Group>
            </div>
            {/* <Image src={image.src} className={classes.image} /> */}
          </div>
        </Container>
      </div>
    );
  }