import { CSSProperties } from "react";

export const layoutStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: `radial-gradient(50% 50% at 50% 50%, #236B8C 0%, #65BCE4 100%)`,
  backgroundSize: "cover",
  minHeight: "100vh",
};

export const cardStyles: CSSProperties = {
  width: "400px",
};

export const titleStyles: CSSProperties = {
  textAlign: "center",
  fontSize: "24px",
};
