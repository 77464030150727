import React from "react";
import { useGetIdentity } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";
// import {
//     // Edit,
//     // Select,
//     useForm,
//     TextInput,
// } from "@pankod/refine-mantine";

import {
    Form,
    Input,
    Select,
    useForm,
    useSelect,
    Upload,
    Radio,
} from "@pankod/refine-antd";

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { Edit } from "../../components/crud/edit"

import { Col, Divider, Row } from 'antd';

// import { Group, Text, useMantineTheme } from '@mantine/core';
// import { IconUpload, IconPhoto, IconX } from '@tabler/icons';
// import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { RichTextEditor } from "@mantine/rte";

import {
    useStrapiUpload,
    getValueProps,
    mediaUploadMapper,
} from "@pankod/refine-strapi-v4";

import MDEditor from "@uiw/react-md-editor";

import { TOKEN_KEY, API_URL } from "../../constants";

import { IUser } from "interfaces";

import { ErrorComponent } from "../../components/pages/error"
import { PasswordInput } from "@pankod/refine-mantine";

// export const UserEdit: React.FC<IResourceComponentsProps> = () => {
export const UserEdit: React.FC = () => {

    const { data: user } = useGetIdentity();

    // const { formProps, saveButtonProps, queryResult } = useForm<IUser>({
    //     metaData: { 
    //         populate: ["avatar"]
    //     },
    // });

    const { id, formProps, saveButtonProps, queryResult } = useForm({
        metaData: { 
            populate: ["avatar"]
        },
        redirect: false,
    });


    // const { selectProps } = useSelect<ICategory>({
    //     resource: "categories",
    //     defaultValue: queryResult?.data?.data?.category?.id,
    //     metaData: { locale: queryResult?.data?.data.locale },
    // });

    const { ...uploadProps } = useStrapiUpload({
        maxCount: 1,
    });


    const sectionStyle: React.CSSProperties = {
        color: '#4DB2E0',
        lineHeight: '48px',
    };

    // console.log(user.abonnement)

    if (user?.id != id){
        return (
            <ErrorComponent />
        )
    }else{
        return (
            // <Edit>
            <Edit saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                    // eslint-disable-next-line
                    // onFinish={(values: any) => {
                    //     return formProps.onFinish?.(mediaUploadMapper(values));
                    // }}
                >

                    <Divider style={sectionStyle} orientation="left">Connexion</Divider>

                    <Row gutter={[16, 16]}>
                        <Col flex={1}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        {/* <Col flex={1}>
                            <Form.Item
                                label="Mot de passe"
                                name="password"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <PasswordInput />
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item
                                label="Confirmation du mot de passe"
                                name="password"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <PasswordInput />
                            </Form.Item>
                        </Col> */}
                    </Row>

                    

                    <Divider style={sectionStyle} orientation="left">Informations</Divider>


                    <Row gutter={[16, 16]}>
                        <Col flex={1}>
                            <Form.Item
                                label="Société"
                                name="societe"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item
                                label="Prénom"
                                name="prenom"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item
                                label="Nom"
                                name="nom"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={[16, 16]}>
                        <Col flex={1}>
                            <Form.Item
                                label="Adresse"
                                name="adresse"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item
                                label="Code postal"
                                name="cp"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item
                                label="Ville"
                                name="ville"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Divider style={sectionStyle} orientation="left">Abonnement</Divider>

                    <Form.Item
                        name="abonnement"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    
                </Form>
            </Edit>
        );
    }
};
