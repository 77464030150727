import {
  useGetIdentity,
  useGetLocale,
  useSetLocale,
} from "@pankod/refine-core";
import {
  ActionIcon,
  Group,
  MantineHeader,
  Title,
  Avatar,
  useMantineColorScheme,
  Menu,
} from "@pankod/refine-mantine";
import { IconSun, IconMoonStars, IconLanguage } from "@tabler/icons";

import i18n from "i18n";

export const Header: React.FC = () => {
  const { data: user } = useGetIdentity();
  const showUserInfo = user && (user.username || user.avatar);

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  const changeLanguage = useSetLocale();
  // const locale = useGetLocale();
  const locale = "fr";
  // const currentLocale = locale();
  const currentLocale = "fr";

  return (
    <MantineHeader height={50} p="xs">
      <Group position="right">
        <Menu shadow="md">

          {/* <Menu.Target>
            <ActionIcon variant="outline">
              <IconLanguage size={18} />
            </ActionIcon>
          </Menu.Target> */}

          {/* <Menu.Dropdown>
            {[...(i18n.languages ?? [])].sort().map((lang: string) => (
              <Menu.Item
                key={lang}
                onClick={() => {
                  changeLanguage(lang);
                }}
                value={lang}
                color={lang === currentLocale ? "primary" : undefined}
                icon={
                  <Avatar
                    src={`/images/flags/${lang}.svg`}
                    size={18}
                    radius="lg"
                  />
                }
              >
                {lang === "fr" ? "Français" : "English"}
              </Menu.Item>
            ))}
          </Menu.Dropdown> */}

        </Menu>
        {/* <ActionIcon
          variant="outline"
          color={dark ? "yellow" : "primary"}
          onClick={() => toggleColorScheme()}
          title="Toggle color scheme"
        >
          {dark ? <IconSun size={18} /> : <IconMoonStars size={18} />}
        </ActionIcon> */}

        {showUserInfo && (
          <Group spacing="xs">
            {/* <Title order={6}>{user?.username} (ref-100{user?.id})</Title> */}
            <Title order={6}>{user?.email}</Title>
            <Avatar src={user?.avatar} alt={user?.username} radius="xl" />
          </Group>
        )}
      </Group>
    </MantineHeader>
  );
};
