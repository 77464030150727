import React from "react";

import {
    useGetIdentity,
} from "@pankod/refine-core";

import { IResourceComponentsProps } from "@pankod/refine-core";

import {
    Form,
    Input,
    Select,
    useForm,
    useSelect,
    Upload,
    Radio,
    Switch,
    Checkbox
} from "@pankod/refine-antd";

import { Col, Divider, Row } from 'antd';

import { Edit } from "../../components/crud/edit"


// import { Group, Text, useMantineTheme } from '@mantine/core';
// import { IconUpload, IconPhoto, IconX } from '@tabler/icons';
// import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { RichTextEditor } from "@mantine/rte";

import {
    useStrapiUpload,
    getValueProps,
    mediaUploadMapper,
} from "@pankod/refine-strapi-v4";

import MDEditor from "@uiw/react-md-editor";

import { TOKEN_KEY, API_URL } from "../../constants";

import { IPraticien } from "interfaces";

import { ErrorComponent } from "../../components/pages/error"

const onChangeSwitch = (checked: boolean) => {
    console.log(`Switch to ${checked}`);
};

const premiumStyle: React.CSSProperties = {
    backgroundColor: '#4DB2E0',
  };
const sectionStyle: React.CSSProperties = {
    color: '#4DB2E0',
    lineHeight: '48px',
  };

export const PraticienEdit: React.FC<IResourceComponentsProps> = () => {

    const { data: user } = useGetIdentity();

    // const { formProps, saveButtonProps, queryResult } = useForm<IPraticien>({
    //     metaData: { populate: ["avatar"] },
    //     redirect: false,
    // });
    const { id, formProps, saveButtonProps, queryResult } = useForm({
        metaData: { populate: ["avatar"] },
        redirect: false,
    });

    // const { selectProps } = useSelect<ICategory>({
    //     resource: "categories",
    //     defaultValue: queryResult?.data?.data?.category?.id,
    //     metaData: { locale: queryResult?.data?.data.locale },
    // });

    const { ...uploadProps } = useStrapiUpload({
        maxCount: 1,
    });

    console.log(user)

    if (user?.data.id_praticien != id){
        return (
            <ErrorComponent />
        )
    }else{

        return (
            <Edit saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                    // eslint-disable-next-line
                    onFinish={(values: any) => {
                        return formProps.onFinish?.(mediaUploadMapper(values));
                    }}
                >
                    <Divider style={sectionStyle} orientation="left">Avant propos</Divider>

                    <p>
                    Vous trouverez ci-dessous des informations nécessaires à la mise en ligne de votre fiche membre.
                    </p>

                    <p>
                    Vous souhaitez voir comment sera afficher votre profil en ligne ? Quoi de mieux que de vous montrer <a target="_blank" rel="noreferrer" href="https://www.spring-medicare.fr/praticien/philippe-lamy-236/">l’exemple de la page Profil de Philippe LAMY</a>, thérapeute systémicien et à l’origine du réseau Spring Medicare.
                    </p>

                    <Divider style={sectionStyle} orientation="left">Publication</Divider>

                    <Form.Item
                        wrapperCol={{ span: 6 }}
                        label="A publier"
                        name="to_publie"
                        valuePropName='checked'
                    >
                        <Switch />
                    </Form.Item>
                    <p>
                    En cochant cette case, votre fiche sera automatiquement prise en compte lors de la prochaine mise à jour du site. Nous mettons à jour le site au moins une fois par semaine.
                    </p>

                    <Divider style={sectionStyle} orientation="left">Entête</Divider>

                    <Form.Item label="Photo de profil (idéalement 600 x 600 pixels au format JPG)">
                        <Form.Item
                            name="avatar"
                            valuePropName="fileList"
                            getValueProps={(data) => getValueProps(data, API_URL)}
                            noStyle
                        >
                            <Upload.Dragger
                                name="files"
                                action={`${API_URL}/api/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                multiple
                                {...uploadProps}
                            >
                                <p className="ant-upload-text">
                                    Glisser votre photo dans cette zone
                                </p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>

                    <Row gutter={[16, 16]}>
                        <Col flex={1}>
                            <Form.Item
                                label="Prénom"
                                name="prenom"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item
                                label="Nom"
                                name="nom"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col flex={3}>
                            <Form.Item
                                label="Nom de votre cabinet"
                                name="cabinet"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label="Titre (par exemple : Spychologue clinicien)"
                                name="titre"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label="Courte description de votre (vos) activitée(s)"
                                name="description"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label="Lieu d'intervention (par exemple : Lyon et en visioconférence)"
                                name="localite"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col flex={1}>
                            <Form.Item
                                label="Email"
                                name="email_profil"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col flex={1}>
                            <Form.Item
                                label="Téléphone"
                                name="telephone"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col flex={3}>
                            <Form.Item
                                label="Site internet (https:// ...)"
                                name="site"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label="Lien Facebook (https:// ...)"
                                name="lien_facebook"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label="Lien LinkedIn (https:// ...)"
                                name="lien_linkedin"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    {user.data.abonnement=="Premium" ? 
                    <>
                        <Divider style={sectionStyle} orientation="left">Gestion Calendrier et Stripe (pour les membres premium)</Divider>
                        <Row style={premiumStyle} gutter={[16, 16]}>
                            <Col span={24}>
                                <Form.Item
                                    label="Lien Calendly (https://)"
                                    name="lien_calendly"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        
                        <Row style={premiumStyle} gutter={[16, 16]}>
                            <Col span={24}>
                                <Form.Item
                                    label="Lien Stripe (https://)"
                                    name="lien_stripe"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                    : 
                      ''
                    }

                    <Divider style={sectionStyle} orientation="left">Contenu</Divider>


                    <Form.Item
                        label="Présentation (décrivez ici votre activité dans les grandes lignes)"
                        name="presentation"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <RichTextEditor 
                            id="rte_presentation"
                            controls={[
                                ['h3'],
                                ['bold', 'italic', 'underline'],
                                ['unorderedList', 'orderedList'],
                                ['alignLeft', 'alignCenter', 'alignRight'],
                                ['clean'],
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Discipline(s) (décrivez en quelques mots vos spécialités et/ou vos pratiques de soins)"
                        name="discipline"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <RichTextEditor 
                            id="rte_discipline"
                            controls={[
                                ['h3'],
                                ['bold', 'italic', 'underline'],
                                ['unorderedList', 'orderedList'],
                                ['alignLeft', 'alignCenter', 'alignRight'],
                                ['clean'],
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Séance(s) (individuelle et/ou en groupe, durée de séance, pratique de la téléconsultation, tarification de vos séances… )"
                        name="seance"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <RichTextEditor 
                            id="rte_seance"
                            controls={[
                                ['h3'],
                                ['bold', 'italic', 'underline'],
                                ['unorderedList', 'orderedList'],
                                ['alignLeft', 'alignCenter', 'alignRight'],
                                ['clean'],
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Formations (présentez votre parcours, vos diplômes, compétences et expériences)"
                        name="formation"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <RichTextEditor 
                            id="rte_formation"
                            controls={[
                                ['h3'],
                                ['bold', 'italic', 'underline'],
                                ['unorderedList', 'orderedList'],
                                ['alignLeft', 'alignCenter', 'alignRight'],
                                ['clean'],
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Spécialitées (expliquez davantage ce que vous faites, mécanismes thérapeutique, typologie de patient, bénéfices pour ces derniers… )"
                        name="specialite"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <RichTextEditor 
                            id="rte_specialite"
                            controls={[
                                ['h3'],
                                ['bold', 'italic', 'underline'],
                                ['unorderedList', 'orderedList'],
                                ['alignLeft', 'alignCenter', 'alignRight'],
                                ['clean'],
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Autres commentaires (mot de la fin, vous pouvez présenter ici une anecdote ou mettre en avant vos valeurs et motivations de faire partie du réseau Spring Medicare)"
                        name="autres"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <RichTextEditor 
                            id="rte_autres"
                            controls={[
                                ['h3'],
                                ['bold', 'italic', 'underline'],
                                ['unorderedList', 'orderedList'],
                                ['alignLeft', 'alignCenter', 'alignRight'],
                                ['clean'],
                            ]}
                        />
                    </Form.Item>

                    <Divider style={sectionStyle} orientation="left">Informations pour le référencement de votre page dans les moteurs de recherche (SEO)</Divider>
                    
                    <p>
                    Il s’agit de décrire le contenu de votre page et ce que l'internaute va y trouver. Les moteurs de recherche utilisent 2 balises principales. Les internautes les voient également s'afficher sur Google et sur votre site.
                    </p>
                    
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label="Balise titre (environ 65 caractères)"
                                name="meta_title"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label="Balise description (environ 160 caractères)"
                                name="meta_description"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Edit>
        );
    }
};
