import React from "react";

import { Refine, Authenticated } from "@pankod/refine-core";
import {
  NotificationsProvider,
  notificationProvider,
  MantineProvider,
  Global,
  ReadyPage,
  ErrorComponent,
  useLocalStorage,
  ColorSchemeProvider,
  ColorScheme,
  DarkTheme,
  LightTheme,
} from "@pankod/refine-mantine";

import { DataProvider } from "@pankod/refine-strapi-v4";
import { MantineInferencer } from "@pankod/refine-inferencer/mantine";
import routerProvider from "@pankod/refine-react-router-v6";
import { useTranslation } from "react-i18next";
import { RefineKbarProvider } from "@pankod/refine-kbar";
import { Title, Sider, Layout, Header } from "components/layout";
import { OffLayoutArea } from "components/offLayoutArea";
import { authProvider, axiosInstance } from "./authProvider";
import { API_URL } from "./constants";

import { AuthPage } from "components/pages/auth";

import { Dashboard } from "pages/dashboard";
import { Profil } from "pages/profil";
import { UserEdit } from "pages/users/edit";
import { PraticienEdit } from "pages/praticiens/edit";


import {
  IconHome2,
  IconGauge,
  IconDeviceDesktopAnalytics,
  IconFingerprint,
  IconCalendarStats,
  IconUser,
  IconSettings,
  IconLogout
} from '@tabler/icons';

const App: React.FC = () => {

  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: true,
  });

  const { t, i18n } = useTranslation();

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const AuthenticatedDashboard = () => {
      return (
          <Authenticated>
              <Dashboard />
          </Authenticated>
      );
  };
  const AuthenticatedProfil = () => {
      return (
          <Authenticated>
              <Profil />
          </Authenticated>
      );
  };

  

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        // theme={colorScheme === "dark" ? DarkTheme : LightTheme}
        theme={{ primaryColor: 'blue' }}
        withNormalizeCSS
        withGlobalStyles
      >
        <Global styles={{ body: { WebkitFontSmoothing: "auto" } }} />
        <NotificationsProvider position="bottom-right">
          <RefineKbarProvider>
            {/* /praticiens/edit/6 */}
            <Refine
              authProvider={authProvider}
              dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
              notificationProvider={notificationProvider}
              ReadyPage={ReadyPage}
              catchAll={<ErrorComponent />}
              // RESSOURCES
              resources={[
                { 
                  name: "dashboard", list: () => null,
                  options: { 
                    route: "dashboard",
                    label: "Tableau de bord"
                  },
                  icon: <IconHome2 />
                },
                {
                  name: "praticiens",
                  options: { 
                    label: "Ma fiche"
                  },
                  // list: MantineInferencer,
                  edit: PraticienEdit,
                  // show: MantineInferencer,
                  // create: MantineInferencer,
                  canDelete: false,
                },
                {
                  name: "users",
                  options: { 
                    label: "Paramètres"
                  },
                  // list: MantineInferencer,
                  edit: UserEdit,
                  canDelete: false,
                },
                // { 
                //   name: "a_propos", list: () => null,
                //   options: { 
                //     route: "apropos",
                //     label: "À propos"
                //   },
                //   icon: <IconHome2 />
                // },
                // { 
                //   name: "profil", list: () => null,
                //   options: { 
                //     route: "profil",
                //     label: "Mon profil"
                //   },
                // },
              ]}
              // FIN RESSOURCES
              Title={Title}
              Sider={Sider}
              Layout={Layout}
              Header={Header}
              routerProvider={{
                ...routerProvider,
                routes: [
                    { 
                        path: "/login", 
                        element: <AuthPage /> 
                    },
                    {
                        path: "/register",
                        element: <AuthPage type="register" />,
                    },
                    {
                        path: "/updatepassword",
                        element: <AuthPage type="updatePassword" />,
                    },
                    {
                        element: <AuthenticatedDashboard />,
                        path: "/dashboard",
                        layout: true
                    },
                    {
                        element: <AuthenticatedProfil />,
                        path: "/profil",
                        layout: true
                    },
                ],
              }}
              LoginPage={AuthPage}
              i18nProvider={i18nProvider}
              OffLayoutArea={OffLayoutArea}
            />
          </RefineKbarProvider>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
